import React, { useState, useEffect } from 'react';
import {
  ComingSoonContainer,
  ComingSoonBg,
  VideoBg,
  ArrowForward,
  ComingSoonContent,
  ComingSoonP,
  ComingSoonTitle,
  ComingSoonBtnWrapper,
  ArrowRight,
  LogoContainer,
  LogoImage
} from '../ComingSoon/ComingSoonElements';
import { Button } from '../../Utilities/ButtonElements';
import Video from '../../Videos/beach.mp4';
import VideoCompressed from '../../Videos/beach.mp4';

const ComingSoon = () => {
  const [hover, setHover] = useState(false);
  const [videoSource, setVideoSource] = useState(Video);

  const onHover = () => {
    setHover(!hover);
  };

  useEffect(() => {
    const getInternetSpeed = () => {
      // Simulating a delay to fetch internet speed
      setTimeout(() => {
        const internetSpeed = 1; // Placeholder for internet speed measurement (e.g., in Mbps)
        const threshold = 2; // Define your threshold for slow internet speed (e.g., 2 Mbps)
  
        if (internetSpeed < threshold) {
          setVideoSource(VideoCompressed);
        } else {
          setVideoSource(Video);
        }
      }, 2000); // Simulating a delay of 2 seconds for the internet speed measurement
    };

    getInternetSpeed();
  }, []);

  return (
    <>
      <ComingSoonContainer>
        <ComingSoonBg>
          <VideoBg autoPlay loop muted src={videoSource} type='video/mp4' />
        </ComingSoonBg>
        <ComingSoonContent>
          <LogoContainer>
            <LogoImage src="/images/logos/main_logo.png" alt="Bitmos logo" />
          </LogoContainer>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <ComingSoonP>
              Coming Soon
            </ComingSoonP>
            <ComingSoonBtnWrapper>
              <Button
                to="https://litepaper.bitmos.io"
                onMouseEnter={onHover}
                onMouseLeave={onHover}
                primary="true"
                dark="true"
              >
                Litepaper {hover ? <ArrowForward /> : <ArrowRight />}
              </Button>
            </ComingSoonBtnWrapper>
          </div>
        </ComingSoonContent>
      </ComingSoonContainer>
    </>
  );
};

export default ComingSoon;
